import getConfig from 'next/config';
import { invoke } from './api';

const { serverRuntimeConfig, publicRuntimeConfig } = getConfig();
const apiUrl = serverRuntimeConfig.apiUrl || publicRuntimeConfig.apiUrl;
const proxyUrl = `${apiUrl}/orchestration`;

console.log('ORCHESTRATION PROXY URI:', proxyUrl); // eslint-disable-line no-console

const api = {
  get: invoke(proxyUrl, 'GET'),
  post: invoke(proxyUrl, 'POST'),
  put: invoke(proxyUrl, 'PUT'),
  patch: invoke(proxyUrl, 'PATCH'),
  delete: invoke(proxyUrl, 'DELETE'),
};

export const getWorkflowChart = (threadPath = 'workOrder/happyPath/thread-main.json') => (
  api.post('/workflow/definition/drawChart', { body: { threadPath } })
);
