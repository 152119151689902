import {
  GET_ARCHIVE_ITEMS,
  GET_ARCHIVE_ITEMS_FAILURE,
  GET_ARCHIVE_ITEMS_SUCCESS,
  GET_ARCHIVE_ITEM,
  GET_ARCHIVE_ITEM_SUCCESS,
  GET_ARCHIVE_ITEM_FAILURE,
} from '../types';
import { actionTypes } from '../actions/archive';

const tableColums = [
  { columnName: 'id', width: 120 },
  { columnName: 'title', width: 300 },
  { columnName: 'episodeTitle', width: 300 },
  { columnName: 'productPath', width: 180 },
  { columnName: 'additionalTitle', width: 300 },
  { columnName: 'PaperworkRemarks', width: 200 },
  { columnName: 'AKATitle', width: 200 },
  { columnName: 'TypeOfMedia', width: 180 },
  { columnName: 'episode', width: 140 },
  { columnName: 'purchaseOrderNumber', width: 140 },
  { columnName: 'distributor', width: 140 },
  { columnName: 'CutRT', width: 180 },
  { columnName: 'season', width: 120 },
  { columnName: 'SubmissionType', width: 200 },
  { columnName: 'submissionId', width: 120 },
  { columnName: 'director', width: 200 },
  { columnName: 'runtime', width: 180 },
  { columnName: 'FlashingLetter', width: 180 },
  { columnName: 'classification', width: 180 },
  { columnName: 'classificationDate', width: 180 },
  { columnName: 'RegNumber', width: 180 },
  { columnName: 'shortFormInsight', width: 300 },
  { columnName: 'created_on', width: 180 },
  { columnName: 'cinemaRegistrationNum', width: 180 },
];

export const archiveDefaultState = {
  error: {
    message: '',
    status: null,
  },
  loading: true,
  rows: [],
  count: 0,
  detail: {},
  tablePreferences: {
    hiddenColumns: [
      'episode',
      'season',
      'purchaseOrderNumber',
      'additionalTitle',
      'PaperworkRemarks',
      'AKATitle',
      'distributor',
      'shortFormInsight',
      'SubmissionType',
      'FlashingLetter',
      'submissionId',
      'created_on',
      'cinemaRegistrationNum',
    ],
    columnOrder: tableColums.map(({ columnName }) => columnName),
    columnWidths: tableColums,
    rowsPerPage: 10,
  },
};

// eslint-disable-next-line default-param-last
const reducer = (state = archiveDefaultState, action) => {
  switch (action.type) {
    case GET_ARCHIVE_ITEMS:
      return { ...state, error: {}, loading: true };
    case GET_ARCHIVE_ITEMS_SUCCESS:
      return { ...state, ...action.payload, error: {}, loading: false };
    case GET_ARCHIVE_ITEMS_FAILURE:
      return { ...state, rows: [], loading: false, error: action.payload.error.message };
    case GET_ARCHIVE_ITEM:
      return { ...state, detail: {}, error: {}, loading: true };
    case GET_ARCHIVE_ITEM_SUCCESS:
      return { ...state, detail: action.payload, error: {}, loading: false };
    case GET_ARCHIVE_ITEM_FAILURE:
      return { ...state, detail: {}, loading: false, error: action.payload.error };
    case actionTypes.RESET_TABLE_PREFERENCES: {
      return {
        ...state,
        tablePreferences: archiveDefaultState.tablePreferences,
      };
    }
    case actionTypes.GET_TABLE_PREFERENCES_SUCCESS: {
      return {
        ...state,
        tablePreferences: action.payload,
      };
    }
    case actionTypes.SET_TABLE_PREFERENCES: {
      return {
        ...state,
        tablePreferences: { ...state.tablePreferences, [action.payload.key]: action.payload.value },
      };
    }
    default:
      return state;
  }
};

export default reducer;
