import { combineReducers } from 'redux';

import app, { appDefaultState } from './app';
import submission, { submissionDefaultState } from './submission';
import submissionV2, {
  submissionDefaultState as submissionDefaultStateV2,
} from './submissionv2';
import submissions, { submissionsDefaultState } from './submissions';
import tasks, { tasksDefaultState } from './tasks';
import myTasks, { myTasksDefaultState } from './my-tasks';
import users, { usersDefaultState } from './users';
import companies, { companiesDefaultState } from './companies';
import authentication, { authenticationDefaultState } from './authentication';
import calendars, { calendarsDefaultState } from './calendars';
import resources, { resourcesDefaultState } from './resources';
import roles, { rolesDefaultState } from './roles';
import permissions, { permissionsDefaultState } from './permissions';
import videos, { videosDefaultState } from './videos';
import notifications, { notificationsDefaultState } from './notifications';
import clientOrders, { clientOrdersDefaultState } from './client-orders';
import genres, { genresDefaultState } from './genres';
import poller, { pollerDefaultState } from './poller';
import tools, { toolsDefaultState } from './tools';
import messaging, { messagingDefaultState } from './messaging';
import uppy, { uppyDefaultState } from './uppy';
import reports, { reportsDefaultState } from './reports';
import collections, { collectionsDefaultState } from './collections';
import archive, { archiveDefaultState } from './archive';
import clientOrder, { clientOrderDefaultState } from './client-order';
import tags, { tagsDefaultState } from './tags';
import assets, { assetsDefaultState } from './assets';
import clipExports, { clipExportsDefaultState } from './clip-exports';
import collection, { collectionDefaultState } from './collection';
import asset, { assetDefaultState } from './asset';
import selfRating, { selfRatingDefaultState } from './self-rating';
import relevantAsset, { relevantAssetDefaultState } from './relevant-asset';

const reducer = combineReducers({
  app,
  submission,
  submissions,
  tasks,
  myTasks,
  users,
  companies,
  authentication,
  calendars,
  resources,
  roles,
  permissions,
  videos,
  notifications,
  clientOrders,
  genres,
  poller,
  tools,
  messaging,
  uppy,
  reports,
  collections,
  archive,
  clientOrder,
  tags,
  assets,
  clipExports,
  submissionV2,
  collection,
  asset,
  selfRating,
  relevantAsset,
});

export const initialState = {
  app: appDefaultState,
  submission: submissionDefaultState,
  submissions: submissionsDefaultState,
  tasks: tasksDefaultState,
  myTasks: myTasksDefaultState,
  users: usersDefaultState,
  companies: companiesDefaultState,
  authentication: authenticationDefaultState,
  calendars: calendarsDefaultState,
  resources: resourcesDefaultState,
  roles: rolesDefaultState,
  permissions: permissionsDefaultState,
  videos: videosDefaultState,
  notifications: notificationsDefaultState,
  clientOrders: clientOrdersDefaultState,
  genres: genresDefaultState,
  poller: pollerDefaultState,
  tools: toolsDefaultState,
  messaging: messagingDefaultState,
  uppy: uppyDefaultState,
  reports: reportsDefaultState,
  collections: collectionsDefaultState,
  archive: archiveDefaultState,
  clientOrder: clientOrderDefaultState,
  tags: tagsDefaultState,
  assets: assetsDefaultState,
  clipExports: clipExportsDefaultState,
  submissionV2: submissionDefaultStateV2,
  collection: collectionDefaultState,
  asset: assetDefaultState,
  selfRating: selfRatingDefaultState,
  relevantAsset: relevantAssetDefaultState,
};

export const mainReducer = (state, action) => reducer(action.type === 'LOGOUT' ? initialState : state, action);

export default mainReducer;
