import { actionTypes } from '../actions/tools';

export const toolsDefaultState = {
  priceCalculator: {
    error: '',
    loading: false,
    data: {},
  },
  workflowChart: {
    error: '',
    loading: false,
    data: null,
  },
};

// eslint-disable-next-line default-param-last
export const reducer = (state = toolsDefaultState, action) => {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.FETCH_WORKFLOW_CHART:
      return { ...state, workflowChart: { ...state.workflowChart, error: '', loading: true } };
    case actionTypes.FETCH_WORKFLOW_CHART_SUCCESS:
      return { ...state, workflowChart: { data: payload, error: '', loading: false } };
    case actionTypes.FETCH_WORKFLOW_CHART_FAILURE:
      return { ...state, workflowChart: { data: {}, error: payload.message, loading: false } };
    default:
      return state;
  }
};

export default reducer;
