import getConfig from 'next/config';
import { invoke } from './api';
import { getCleanId } from '../utils/utils';

const { serverRuntimeConfig, publicRuntimeConfig } = getConfig();
const apiUrl = serverRuntimeConfig.apiUrl || publicRuntimeConfig.apiUrl;
const proxyUrl = `${apiUrl}/data-api`;

console.log('DATA API PROXY URI:', proxyUrl); // eslint-disable-line no-console

const api = {
  get: invoke(proxyUrl, 'GET'),
  post: invoke(proxyUrl, 'POST'),
  put: invoke(proxyUrl, 'PUT'),
  patch: invoke(proxyUrl, 'PATCH'),
  delete: invoke(proxyUrl, 'DELETE'),
};

/**
 * Authenticated routes
 */
export const createCollectionFromAsset = (assetId) => api.post(`/collections/asset/${assetId}`);

export const addAssetsToCollection = (id, assetIds) => api.post(`/collections/${id}/assets`, { body: { assetIds } });

export const removeAssetsFromCollection = (assetIds) => api.delete('/collections/assets', { body: { assetIds } });

export const getArchivePdfFiles = (assetId) => api.get(`/files/${assetId}/pdf`);

export const getHasArchivePdfFiles = (assetId) => api.get(`/files/${assetId}/pdf/any`);

export const getArchivePdfFileUrl = (assetId, fileName) => api.get(`/files/${assetId}/pdf/${fileName}`);

export const searchCollections = (body) => api.post('/search/collections', { body });

export const searchAssets = (body) => api.post('/search/assets', { body });

export const searchArchive = (body) => api.post('/search/archive', { body });

export const getArchiveAsset = (id) => api.get(`/archive/assets/${id}`);

export const getCollection = (id) => api.get(`/collections/${id}`);

export const getAssetsByType = (collections, assetType, orderBy, isSeries) => api.post('/collections/assets', { body: { collections, assetType, orderBy, isSeries } });

export const getAssetTypes = (collectionIds, isSeries, itemListType) => api.post('/collections/asset-types', { body: { collectionIds, isSeries, itemListType } });

export const addAssetToCollection = (id, assetId) => api.post(`collections/${getCleanId(id)}/assets/${getCleanId(assetId)}`);

export const updateAssets = (body) => api.patch('assets', { body });

export const updateAsset = (id, body) => api.patch(`assets/${id}/with-metadata`, { body });

export const getAsset = (id) => api.get(`/assets/${id}`);

export const removeAssetFromCollection = (id, assetId) => api.delete(`collections/${getCleanId(id)}/assets/${getCleanId(assetId)}`);

export const saveCollection = (body) => api.post('collections', { body });

export const updateCollection = (id, body) => api.patch(`collections/${id}`, { body });

export const associateCollections = (id, childId) => api.put(`collections/${id}/${childId}`);

export const removeCollectionAssociations = (id, childId) => api.delete(`collections/${id}/${childId}`);

export const getRelevantAsset = (collectionId) => api.get(`/relevant-asset/${collectionId}`);

export const updateRelevantAssetOverride = (currentAssetId, assetId) => api.post('/relevant-asset/override', { body: { currentAssetId, assetId: assetId || undefined } });

export const getPossibleRelevantAssets = (collectionId, productPath, episode, version) => api.get(`/relevant-asset/${collectionId}/assets`, {
  query: {
    productPath,
    episode,
    version,
  },
});